<template>
    <div class="wrapper">
        <div class="title-wrapper">
            <h6><slot /></h6>
            <p v-if="subtitle">
                {{ subtitle }}
            </p>
        </div>
        <label class="switch">
            <input
                :class="{ checked: proxyChecked }"
                type="checkbox"
                :disabled="disabled"
                v-model="proxyChecked"
                :value="value"
                @input="$emit('change', value)"
            />
            <div class="slider"></div>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'CRToggle',
        props: {
            checked: {
                type: [Array, Boolean],
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                default: null,
            },
            subtitle: {
                type: String,
                default: '',
            },
        },
        emits: ['update:checked', 'change'],
        computed: {
            proxyChecked: {
                get() {
                    return this.checked;
                },

                set(val) {
                    this.$emit('update:checked', val);
                },
            },
        },
    };
</script>

<style scoped lang="scss">
    .wrapper {
        @include row-align-center;

        .title-wrapper {
            h6 {
                margin-right: 10px;
            }

            p {
                position: absolute;
                font-size: $font-14;
                font-style: italic;
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 25px;
    }

    .switch input {
        display: none;
    }

    .slider {
        cursor: pointer;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;

        background-color: $white;
        box-shadow: $box-shadow-dark;

        border-radius: $br-30;

        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        content: '';
        position: absolute;
        height: 18px;
        width: 18px;

        left: 4px;
        bottom: 4px;

        background-color: $primary;
        border-radius: $br-100;

        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input.checked + .slider {
        background-color: $primary;
    }

    input.checked + .slider:before {
        background-color: $white;

        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
    }
</style>
