<template>
    <div class="periods-settings__wrapper">
        <h2 class="mb-30">{{ $t('otherSettings.orderPeriods') }}</h2>
        <h3>{{ $t('otherSettings.createOrderPeriods') }}</h3>
        <div class="tag__wrapper">
            <CRTag
                class="tag"
                v-for="period in periods"
                :key="period.id"
                :delete-allowed="true"
                type="additional"
                @delete="deletePeriod(period)"
                svg-color="#00595A"
            >
                {{ $filters.getTranslationByKey(period.name) }}
            </CRTag>
        </div>
        <CRInput
            class="mb-30"
            :label="$t('otherSettings.orderPeriod')"
            :placeholder="$t('otherSettings.orderPeriod')"
            type="number"
            v-model="form.amount"
            :errors="errors?.amount"
            @onChange="$filters.clearError(errors, 'amount')"
        />
        <CRButton class="button" @click="createPeriod">
            {{ $t('buttons.add') }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import { toasterMixin } from '@/utils/mixins';
    import { PeriodApi } from '@/api';
    import CRTag from '@/components/Common/Tags/CRTag';

    export default {
        name: 'PeriodsSettings',
        components: { CRTag, CRInput, CRButton },
        mixins: [toasterMixin],
        data() {
            return {
                periods: [],
                form: {
                    amount: null,
                },
                errors: {},
            };
        },
        methods: {
            async createPeriod() {
                try {
                    const response = await PeriodApi.store(this.form);
                    this.periods.push(response.data);

                    this.notify(response.data.name);

                    this.form.amount = null;
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async fetchPeriods() {
                try {
                    const response = await PeriodApi.getAll();
                    this.periods = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async deletePeriod(period) {
                try {
                    await PeriodApi.destroy(period.id);
                    this.notify(period.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                    this.periods = this.periods.filter((term) => term.id !== period.id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.fetchPeriods();
        },
    };
</script>

<style scoped lang="scss">
    .periods-settings__wrapper {
        .tag__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            margin: 20px 0;

            .tag {
                margin: 0 8px 8px 0;
            }
        }
    }
</style>
