import { translation } from '@/helpers/TranslationHelper';
import { toast } from '@/helpers/ToastHelper';
import { TYPE } from 'vue-toastification';

export const handleUploadImage = (files, array) => {
    Array.from(files).forEach((file) => {
        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg'];

            if (allowedTypes.indexOf(file.type) === -1) {
                toast(translation('errors.incorrectImageType'), { type: TYPE.ERROR });
                return false;
            }

            array.push(file);
        }
    });
};
