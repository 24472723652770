<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ $t('mealKits.createCombination') }}</h2>
            <LangButtons class="lang-buttons" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="input"
                v-model="form.name[locale]"
                :label="$t('common.combinationName')"
                :placeholder="$t('placeholder.enterCombinationName')"
                :errors="errors?.name"
                @onChange="$filters.clearError(errors, 'name')"
            />
            <div class="btn__wrapper">
                <CRButton
                    class="btn"
                    v-for="meal in meals"
                    :key="meal.id"
                    :active="form.combination.includes(meal.type)"
                    @click="handelToggleMealType(meal.type)"
                    pattern="toggle"
                >
                    {{ $t(`common.${meal.type}`) }}
                </CRButton>
                <ErrorText class="error" v-for="(error, index) in errors.combination" :key="index">
                    {{ error }}
                </ErrorText>
            </div>

            <CRButton @click="createCombination" :disabled="!form.combination.length">
                {{ $t('buttons.add') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { CombinationApi } from '@/api';
    import ErrorText from '@/components/Common/Tags/ErrorText.vue';
    import { langMixin, toasterMixin } from '@/utils/mixins';

    export default {
        name: 'CreateCombinationModal',
        components: { BasicModal, CRButton, CRInput, ErrorText },
        mixins: [langMixin, toasterMixin],
        props: {
            meals: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['close', 'newCombination'],
        data() {
            return {
                isLoading: false,

                form: {
                    name: {
                        en: '',
                        lt: '',
                    },
                    combination: [],
                },

                errors: {},
            };
        },
        watch: {
            'form.combination.length'() {
                this.$filters.clearError(this.errors, 'combination');
            },
        },
        methods: {
            handelToggleMealType(value) {
                if (this.form.combination.includes(value)) {
                    this.form.combination = this.form.combination.filter((itemId) => itemId !== value);
                } else {
                    this.form.combination.push(value);
                }
            },

            async createCombination() {
                try {
                    const response = await CombinationApi.store(this.form);
                    this.$emit('newCombination', response.data);

                    this.form = {
                        name: '',
                        combination: [],
                    };

                    this.notify(response.data.name);

                    this.$emit('close');
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start-justify-start;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .lang-buttons {
            margin-bottom: 0 !important;
        }

        h2 {
            margin-bottom: 10px;
        }

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 20px;

            .btn {
                margin-right: 8px;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .input {
            margin-top: 24px;
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .error {
            width: 100%;
        }
    }
</style>
