<template>
    <div class="combinations-settings__wrapper">
        <h2 class="mb-30">{{ $t('otherSettings.combinations') }}</h2>
        <div class="f-align-center">
            <h3 class="mr-10">{{ $t('otherSettings.createCombinations') }}</h3>
            <CRButton plus @click="isCreateCombinationModalOpened = true" />
        </div>
        <div class="combinations-container">
            <div class="tag__wrapper">
                <CRTag
                    class="tag"
                    v-for="combination in combinations"
                    :key="combination.id"
                    :delete-allowed="true"
                    type="additional"
                    @delete="deleteCombination(combination)"
                    svg-color="#00595A"
                >
                    {{ $filters.getTranslationByKey(combination.name) }}
                </CRTag>
            </div>
        </div>

        <CreateCombinationModal
            v-if="isCreateCombinationModalOpened"
            :meals="meals"
            @newCombination="addNewCombination"
            @close="isCreateCombinationModalOpened = false"
        />
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CreateCombinationModal from '@/components/Modals/CreateCombinationModal.vue';
    import { CombinationApi, MealApi } from '@/api';
    import { toasterMixin } from '@/utils/mixins';

    export default {
        name: 'CombinationsSettings',
        components: { CRTag, CreateCombinationModal, CRButton },
        mixins: [toasterMixin],
        data() {
            return {
                isCreateCombinationModalOpened: false,
                meals: [],
                combinations: [],
                errors: {},
            };
        },
        methods: {
            addNewCombination(combination) {
                this.combinations.push(combination);
            },

            async fetchCombinations() {
                try {
                    const response = await CombinationApi.getAll();
                    this.combinations = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async fetchMeals() {
                try {
                    const response = await MealApi.getAll();
                    this.meals = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async deleteCombination(combination) {
                try {
                    await CombinationApi.destroy(combination.id);
                    this.combinations = this.combinations.filter((term) => term.id !== combination.id);

                    this.notify(combination.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.fetchCombinations();
            await this.fetchMeals();
        },
    };
</script>

<style scoped lang="scss">
    .combinations-settings__wrapper {
        .combinations-container {
            .tag__wrapper {
                @include row-align-start;
                flex-wrap: wrap;

                margin: 30px 0;

                .tag {
                    margin: 0 8px 8px 0;
                }
            }
        }
    }
</style>
