<template>
    <div>
        <div class="other-settings__wrapper">
            <h1 class="mb-30">{{ $t('otherSettings.title') }}</h1>
            <PeriodsSettings class="mt-20" />
            <CombinationsSettings class="mt-30" />
            <AllergensSettings class="mt-30" />
            <AdditionsSettings class="mt-30" />
            <DietPlansSettings class="mt-30" />
            <DislikedProductsSettings class="mt-30" />
            <PromotionSettings class="mt-30" />
            <EvaluateDishSettings class="mt-30" />
        </div>
    </div>
</template>

<script>
    import PeriodsSettings from '@/components/Admin/OtherSettings/PeriodsSettings';
    import CombinationsSettings from '@/components/Admin/OtherSettings/CombinationsSettings';
    import AllergensSettings from '@/components/Admin/OtherSettings/AllergensSettings';
    import AdditionsSettings from '@/components/Admin/OtherSettings/AdditionsSettings';
    import DietPlansSettings from '@/components/Admin/OtherSettings/DietPlansSettings';
    import DislikedProductsSettings from '@/components/Admin/OtherSettings/DislikedProductsSettings';
    import PromotionSettings from '@/components/Admin/OtherSettings/PromotionSettings';
    import EvaluateDishSettings from '@/components/Admin/OtherSettings/EvaluateDishSettings';

    export default {
        name: 'OtherSettings',
        components: {
            DietPlansSettings,
            AdditionsSettings,
            AllergensSettings,
            PeriodsSettings,
            CombinationsSettings,
            DislikedProductsSettings,
            PromotionSettings,
            EvaluateDishSettings,
        },
    };
</script>

<style scoped lang="scss">
    .other-settings__wrapper {
        max-width: 633px;

        padding-bottom: 30px;

        h1 {
            display: none;

            @include media($lg) {
                display: block;
            }
        }
    }
</style>
