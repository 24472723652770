import { AllergenTypeEnum } from '@/utils/enums';
import { translation } from '@/helpers/TranslationHelper';

export const typeButtons = [
    {
        id: 1,
        type: AllergenTypeEnum.ORDER,
        name: translation('otherSettings.typeOrder'),
    },
    {
        id: 2,
        type: AllergenTypeEnum.DISH,
        name: translation('otherSettings.typeDish'),
    },
];
