<template>
    <CreationSection :title="$t('common.allergens')" class="wrapper">
        <h3 class="mb-25 mt-30">{{ $t('otherSettings.createAllergens') }}</h3>
        <div class="type-buttons">
            <CRButton
                :active="button.type === selectedType"
                size="x-small"
                v-for="button in typeButtons"
                :key="button.id"
                pattern="toggle"
                @click="selectedType = button.type"
            >
                {{ button.name }}
            </CRButton>
        </div>
        <CRButton class="add-button" @click="isCreateModalOpened = true">
            {{ $t('buttons.add') }}
        </CRButton>
        <div class="btn__wrapper">
            <CRButton
                class="btn"
                v-for="allergen in allergensBySelectedType"
                :key="allergen.id"
                :active="!allergen.isHidden"
                @click="updateAllergenIsHidden(allergen)"
                pattern="toggle"
            >
                <template #icon>
                    <VegetarianSvg />
                </template>
                {{ allergenButtonName(allergen) }}
                <PenSvg v-if="editedAllergen?.id !== allergen.id" @click.stop="selectAllergen(allergen)" class="icon" />
                <CheckSvg v-if="editedAllergen?.id === allergen.id" class="icon" @click.stop="updateAllergen" />
            </CRButton>
        </div>

        <!-- Edit allergen -->
        <div v-if="editedAllergen">
            <div>
                <LangButtons :locale="locale" @selectLocale="selectLocale" />
                <CRInput
                    v-model="editedAllergen.name[locale]"
                    class="input"
                    :label="$t('otherSettings.allergenName')"
                    :placeholder="$t('otherSettings.allergenName')"
                    :errors="errors?.name"
                    @onChange="$filters.clearError(errors, 'name')"
                />
            </div>
            <CRInput
                v-if="selectedType === AllergenTypeEnum.ORDER"
                v-model="editedAllergen.percentage"
                class="input"
                :label="$t('placeholder.increaseInPrice')"
                :placeholder="$t('placeholder.increaseInPrice')"
                :help-text="$t('common.addPercentageToIncreaseKitPrice')"
                :errors="errors?.percentage"
                @onChange="$filters.clearError(errors, 'percentage')"
            />
            <div class="buttons__container">
                <CRButton @click="updateAllergen">
                    {{ $t('buttons.update') }}
                </CRButton>
                <CRButton v-if="!editedAllergen.isSpecific" color="danger" @click="deleteAllergen">
                    {{ $t('buttons.delete') }}
                </CRButton>
            </div>
        </div>

        <CreateAllergenModal
            :type="selectedType"
            v-if="isCreateModalOpened"
            @close="isCreateModalOpened = false"
            @created="allergenCreationHandler"
        />
    </CreationSection>
</template>

<script>
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import PenSvg from '@/assets/icons/buttons/pen-white.svg?inline';
    import CheckSvg from '@/assets/icons/checks/check-icon.svg?inline';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import { AllergenApi } from '@/api';
    import { AllergenTypeEnum } from '@/utils/enums';
    import { cloneDeep, throttle } from 'lodash';
    import CreateAllergenModal from '@/components/Modals/CreateAllergenModal';
    import { typeButtons } from '@/config/allergens/allergenTypeButtons';

    export default {
        name: 'AllergensSettings',
        components: {
            CreateAllergenModal,
            CreationSection,
            CRButton,
            VegetarianSvg,
            CRInput,
            PenSvg,
            CheckSvg,
            LangButtons,
        },
        mixins: [langMixin, toasterMixin],
        data() {
            return {
                AllergenTypeEnum,
                selectedType: AllergenTypeEnum.ORDER,
                typeButtons,
                allergens: [],
                editedAllergen: null,
                errors: {},
                isCreateModalOpened: false,
            };
        },
        computed: {
            allergensBySelectedType() {
                return this.allergens.filter((allergen) => allergen.type === this.selectedType);
            },
        },

        watch: {
            selectedType() {
                this.editedAllergen = null;
            },
        },

        methods: {
            async getAllergens() {
                try {
                    const response = await AllergenApi.getAll();
                    this.allergens = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            selectAllergen(allergen) {
                this.editedAllergen = cloneDeep(allergen);
            },

            updateAllergenIsHidden: throttle(async function (allergen) {
                try {
                    const response = await AllergenApi.update(allergen.id, { isHidden: !allergen.isHidden });
                    allergen.isHidden = response.data.isHidden;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1500),

            async updateAllergen() {
                try {
                    const response = await AllergenApi.update(this.editedAllergen.id, {
                        percentage: this.editedAllergen.percentage,
                        name: this.editedAllergen.name,
                    });

                    let allergen = this.allergensBySelectedType.find((item) => item.id === response.data.id);
                    if (allergen) {
                        allergen.percentage = response.data.percentage;
                        allergen.name = response.data.name;
                    }
                    this.notify(this.editedAllergen.name, this.CRUDTypeEnum.UPDATED, this.TYPE.SUCCESS);
                    this.editedAllergen = null;
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            allergenCreationHandler(allergen) {
                this.allergens.push(allergen);
                this.isCreateModalOpened = false;
            },

            allergenButtonName(allergen) {
                let nameString = this.$filters.getTranslationByKey(allergen.name);
                if (this.selectedType === AllergenTypeEnum.ORDER) nameString += ` - ${allergen.percentage}%`;
                return nameString;
            },

            async deleteAllergen() {
                try {
                    const allergenId = this.editedAllergen.id;
                    await AllergenApi.destroy(allergenId);
                    this.notify(this.editedAllergen.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                    this.allergens = this.allergens.filter((allergen) => allergen.id !== allergenId);
                    this.editedAllergen = null;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.getAllergens();
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        .add-button {
            margin-bottom: 20px;
        }

        .type-buttons {
            @include row-align-center;

            * {
                margin: 0 8px 20px 0;
            }
        }

        .buttons__container {
            @include row-align-center;

            :nth-child(1) {
                margin-right: 8px;
            }
        }

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 20px;

            .btn {
                margin-right: 8px;
                margin-bottom: 10px;

                .icon {
                    width: 13px;
                    margin-left: 10px;

                    &:deep() {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }

        .input {
            margin: 30px 0 20px 0;
        }
    }
</style>
