<template>
    <div class="diet-plans__wrapper">
        <h2 class="mb-30">{{ $t('otherSettings.dietPlans') }}</h2>
        <h3 class="mb-30">{{ $t('otherSettings.createDietPlans') }}</h3>
        <div class="tag__wrapper" v-if="dietPlans">
            <CRTag
                class="tag"
                delete-allowed
                editAllowed
                svg-color="#00595A"
                icon="vegetarian"
                type="additional"
                v-for="plan in dietPlans"
                :key="plan.id"
                @delete="deleteDietPlan(plan)"
                @edit="handleEdit(plan)"
            >
                {{ $filters.getTranslationByKey(plan.name) }}
            </CRTag>
        </div>
        <LangButtons class="mb-30" :locale="locale" @selectLocale="selectLocale" />
        <CRInput
            v-model="inputModel[locale]"
            class="mb-30"
            :label="$t('otherSettings.dietPlanName')"
            :placeholder="$t('otherSettings.dietPlanName')"
            :errors="errors?.name"
            @onChange="$filters.clearError(errors, 'name')"
        />
        <CRButton @click="createDietPlan">{{ buttonLabel }}</CRButton>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { DietPlanApi } from '@/api';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { cloneDeep } from 'lodash';

    export default {
        name: 'DietPlansSettings',
        components: { LangButtons, CRButton, CRInput, CRTag },
        mixins: [langMixin, toasterMixin],
        data() {
            return {
                dietPlans: [],
                name: {
                    en: '',
                    lt: '',
                },
                editedDietPlan: null,
                errors: {},
            };
        },
        methods: {
            async getDietPlans() {
                try {
                    const response = await DietPlanApi.getAll();
                    this.dietPlans = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handleEdit(dietPlan) {
                this.editedDietPlan = cloneDeep(dietPlan);
            },

            async createDietPlan() {
                try {
                    if (this.editedDietPlan) {
                        const response = await DietPlanApi.update(
                            { name: this.editedDietPlan.name },
                            this.editedDietPlan.id
                        );

                        let neededDietPlanIndex = this.dietPlans.findIndex(
                            (dietPlan) => dietPlan.id === this.editedDietPlan.id
                        );
                        this.dietPlans[neededDietPlanIndex] = response.data;

                        this.notify(response.data.name, this.CRUDTypeEnum.UPDATED, this.TYPE.SUCCESS);

                        this.editedDietPlan = null;
                    } else {
                        const response = await DietPlanApi.store({ name: this.name });
                        this.dietPlans.push(response.data);

                        this.notify(response.data.name);

                        this.name = {
                            en: '',
                            lt: '',
                        };
                    }
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async deleteDietPlan(plan) {
                try {
                    await DietPlanApi.destroy(plan.id);
                    this.dietPlans = this.dietPlans.filter((dietPlan) => dietPlan.id !== plan.id);
                    this.notify(plan.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        computed: {
            inputModel() {
                return this.editedDietPlan ? this.editedDietPlan.name : this.name;
            },

            buttonLabel() {
                return this.editedDietPlan ? this.$t('buttons.edit') : this.$t('buttons.add');
            },
        },

        async mounted() {
            setTimeout(async () => {
                await this.getDietPlans();
            }, 1000);
        },
    };
</script>

<style scoped lang="scss">
    .diet-plans__wrapper {
        .tag__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            margin: 30px 0;

            .tag {
                margin: 0 8px 8px 0;
            }
        }
    }
</style>
