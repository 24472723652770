<template>
    <div class="disliked-products__wrapper">
        <h2 class="mb-30">{{ $t('otherSettings.dislikedProducts') }}</h2>
        <h3 class="mb-30">{{ $t('otherSettings.createDislikedProducts') }}</h3>
        <div class="tag__wrapper" v-if="dislikedProducts">
            <CRTag
                class="tag"
                delete-allowed
                edit-allowed
                svg-color="#00595A"
                icon="vegetarian"
                type="additional"
                v-for="product in dislikedProducts"
                :key="product.id"
                @delete="deleteProduct(product)"
                @edit="handleEdit(product)"
            >
                {{ $filters.getTranslationByKey(product.name) }}
            </CRTag>
        </div>
        <LangButtons class="mb-30" :locale="locale" @selectLocale="selectLocale" />
        <CRInput
            class="mb-30"
            :label="$t('otherSettings.dislikedProductName')"
            :placeholder="$t('otherSettings.dislikedProductName')"
            v-model="inputModel[locale]"
            :errors="errors?.name"
            @onChange="$filters.clearError(errors, 'name')"
        />
        <CRButton @click="createProduct">{{ buttonLabel }}</CRButton>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { ProductApi } from '@/api';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { cloneDeep } from 'lodash';

    export default {
        name: 'DislikedProductsSettings',
        components: { LangButtons, CRButton, CRInput, CRTag },
        mixins: [langMixin, toasterMixin],
        data() {
            return {
                dislikedProducts: [],
                name: {
                    en: '',
                    lt: '',
                },
                errors: {},
                editedProduct: null,
            };
        },
        methods: {
            async getDislikedProducts() {
                try {
                    const response = await ProductApi.getAll();
                    this.dislikedProducts = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handleEdit(product) {
                this.editedProduct = cloneDeep(product);
            },

            async createProduct() {
                try {
                    if (this.editedProduct) {
                        const response = await ProductApi.update(
                            { name: this.editedProduct.name },
                            this.editedProduct.id
                        );

                        let neededProductIndex = this.dislikedProducts.findIndex(
                            (dislikedProduct) => dislikedProduct.id === this.editedProduct.id
                        );

                        this.dislikedProducts[neededProductIndex] = response.data;

                        this.notify(response.data.name, this.CRUDTypeEnum.UPDATED, this.TYPE.SUCCESS);

                        this.editedProduct = null;
                    } else {
                        const response = await ProductApi.store({ name: this.name });
                        this.dislikedProducts.push(response.data);

                        this.notify(response.data.name);

                        this.name = {
                            en: '',
                            lt: '',
                        };
                    }
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async deleteProduct(dislikedProduct) {
                try {
                    await ProductApi.destroy(dislikedProduct.id);
                    this.notify(dislikedProduct.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                    this.dislikedProducts = this.dislikedProducts.filter(
                        (product) => product.id !== dislikedProduct.id
                    );
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        computed: {
            inputModel() {
                return this.editedProduct ? this.editedProduct.name : this.name;
            },

            buttonLabel() {
                return this.editedProduct ? this.$t('buttons.edit') : this.$t('buttons.add');
            },
        },

        async mounted() {
            setTimeout(async () => {
                await this.getDislikedProducts();
            }, 1500);
        },
    };
</script>

<style scoped lang="scss">
    .disliked-products__wrapper {
        .tag__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            margin: 30px 0;

            .tag {
                margin: 0 8px 8px 0;
            }
        }
    }
</style>
