<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2 class="mb-20">{{ $t('otherSettings.createAddition') }}</h2>
            <LangButtons class="mb-20" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="mb-20"
                @onChange="$filters.clearError(errors, 'name')"
                :errors="errors?.name"
                v-model="name[locale]"
                :label="$t('otherSettings.additionName')"
                :placeholder="$t('otherSettings.additionName')"
            />
            <CRInput
                class="mb-20"
                @onChange="$filters.clearError(errors, 'price')"
                :errors="errors?.price"
                :label="$t('otherSettings.additionPrice')"
                :placeholder="$t('otherSettings.additionPrice')"
                v-model="price"
                type="number"
            />

            <div v-if="imageUrl" class="img-preview" :style="backgroundStyles(imageUrl)" />

            <CRButton class="mb-20" upload pattern="thin" @handleUpload="handleUpload">
                {{ $t('otherSettings.addThumbnail') }}
            </CRButton>

            <CRButton @click="createAddition">
                {{ $t('buttons.add') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { handleUploadImage } from '@/helpers/UploadImageHelper';
    import BasicModal from '@/components/Modals/BasicModal';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import { AdditionApi } from '@/api';
    import LangButtons from '@/components/Common/Buttons/LangButtons';

    export default {
        name: 'CreateAdditionModal',
        components: { LangButtons, CRButton, CRInput, BasicModal },
        mixins: [langMixin, toasterMixin],
        emits: ['close', 'created'],
        data() {
            return {
                backgroundStyles,
                name: {
                    en: '',
                    lt: '',
                },
                price: null,
                images: [],
                errors: {},
                image: null,
                imageUrl: null,
            };
        },
        methods: {
            async handleUpload($event) {
                handleUploadImage($event.target.files, this.images);

                this.image = $event.target.files[0];
                this.imageUrl = URL.createObjectURL($event.target.files[0]);
            },

            async createAddition() {
                try {
                    let formData = new FormData();
                    let payload = { name: this.name, price: this.price };

                    if (this.imageUrl) {
                        formData.append('pictures[]', this.image);
                        formData.append('picturable_type', 'addition');
                    }
                    formData.append('payload', JSON.stringify(payload));

                    const response = await AdditionApi.store(formData);

                    this.notify(response.data.name);

                    this.$emit('created', response.data);
                    this.$emit('close');
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .container {
        padding: 40px;

        .img-preview {
            margin-bottom: 20px;

            width: 300px;
            height: 300px;

            background-image: url('../../assets/images/backgrounds/default-addition-bg.png');

            box-shadow: $box-shadow-dark;
            border-radius: $br-10;
        }
    }
</style>
