<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2 class="subtitle">{{ $t('otherSettings.createAllergen') }}</h2>
            <LangButtons class="lang-buttons" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="input"
                @onChange="$filters.clearError(errors, 'name')"
                :errors="errors?.name"
                v-model="name[locale]"
                :label="$t('otherSettings.allergenName')"
                :placeholder="$t('otherSettings.allergenName')"
            />
            <CRInput
                class="input"
                v-if="type === AllergenTypeEnum.ORDER"
                @onChange="$filters.clearError(errors, 'percentage')"
                :errors="errors?.percentage"
                :label="$t('placeholder.increaseInPrice')"
                :placeholder="$t('placeholder.increaseInPrice')"
                v-model="percentage"
                type="number"
            />
            <CRButton @click="createAllergen">
                {{ $t('buttons.add') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import { AllergenApi } from '@/api';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { AllergenTypeEnum } from '@/utils/enums';

    export default {
        name: 'CreateAllergenModal',
        components: { LangButtons, CRButton, CRInput, BasicModal },
        mixins: [langMixin, toasterMixin],
        props: {
            type: {
                type: String,
                default: AllergenTypeEnum.ORDER,
                validator: (value) => value.match(/(order|dish)/),
            },
        },
        emits: ['close', 'created'],
        data() {
            return {
                AllergenTypeEnum,
                name: {
                    en: '',
                    lt: '',
                },
                percentage: null,
                errors: {},
            };
        },
        methods: {
            async createAllergen() {
                try {
                    const response = await AllergenApi.store({
                        name: this.name,
                        percentage: this.percentage,
                        type: this.type,
                    });

                    this.$emit('created', response.data);
                    this.notify(response.data.name);
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .container {
        padding: 40px;

        .subtitle,
        .lang-buttons,
        .input {
            margin-bottom: 20px;
        }
    }
</style>
