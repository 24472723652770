<template>
    <div class="wrapper">
        <div class="title__wrapper">
            <h3 class="title">{{ title }}</h3>
            <slot name="title-btn" />
        </div>
        <div class="btn__wrapper" v-if="$slots['btn-wrapper']">
            <slot name="btn-wrapper" />
        </div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CreationSection',
        props: {
            title: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        margin-top: 30px;

        .title__wrapper {
            @include row-align-center;
            margin-bottom: 10px;

            h3 {
                margin-right: 10px;
            }
        }

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 20px;

            &:deep(.btn) {
                margin-right: 8px;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($lg) {
        .wrapper {
            .title__wrapper {
                margin-bottom: 15px;

                h3 {
                    font-size: $font-24;
                    margin-right: 10px;
                }
            }
        }
    }
</style>
