<template>
    <div class="promotion__wrapper" v-if="evaluateDishSetting">
        <h2 class="mb-30">{{ $t('otherSettings.hideDishEvaluationDisplay') }}</h2>
        <CRToggle
            class="mb-20"
            :checked="evaluateDishSetting.isDisabled"
            :value="evaluateDishSetting.isDisabled"
            @change="updateEvaluateDishSetting"
        >
            <span>{{ $t('otherSettings.hideEvaluationDisplayForUsers') }}</span>
        </CRToggle>
    </div>
</template>

<script>
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import { EvaluateDishSettingApi } from '@/api';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'EvaluateDishSettings',
        components: { CRToggle },
        data() {
            return {
                evaluateDishSetting: null,
            };
        },
        methods: {
            async getEvaluateDishSetting() {
                try {
                    const response = await EvaluateDishSettingApi.get();
                    this.evaluateDishSetting = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updateEvaluateDishSetting() {
                try {
                    const response = await EvaluateDishSettingApi.update(this.evaluateDishSetting.id, {
                        isDisabled: !this.evaluateDishSetting.isDisabled,
                    });

                    this.evaluateDishSetting = response.data;
                    this.$filters.toast(
                        `${this.$t('otherSettings.evaluationDisplay')}${this.$t('otherSettings.updated')}`,
                        { type: TYPE.SUCCESS }
                    );
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.getEvaluateDishSetting();
        },
    };
</script>
