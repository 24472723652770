<template>
    <div class="additions__wrapper">
        <h2 class="mb-30">{{ $t('otherSettings.kitAdditions') }}</h2>
        <h3 class="mb-30">{{ $t('otherSettings.createAdditions') }}</h3>
        <div class="additions-container">
            <CRCheckbox
                class="radio"
                v-for="addition in additions"
                :key="addition.id"
                :value="addition.id"
                :checked="!addition.isHidden"
                @change="additionClickHandler(addition)"
                is-box
            >
                <template #content>
                    <div class="addition__inner">
                        <div class="content">
                            <h6 class="name">
                                {{ $filters.getTranslationByKey(addition.name) }}
                            </h6>
                            <p>{{ $t('cart.forWorkingDays', { amount: 1 }) }}</p>
                            <h6>{{ addition.price }}€</h6>
                        </div>
                        <div class="operational-buttons">
                            <CRButton
                                @click.prevent="editAdditionHandler(addition)"
                                class="button"
                                :edit="editedAddition?.id !== addition.id"
                                :plain-checked="editedAddition?.id === addition.id"
                                size="x-small"
                            />
                            <CRButton @click.prevent="deleteAddition(addition)" class="button" close />
                        </div>
                        <div class="img" :style="backgroundStyles(addition.picture?.picturePath.thumbnail)" />
                    </div>
                </template>
            </CRCheckbox>
        </div>

        <!-- Edit addition -->
        <div class="edit-container" v-if="editedAddition">
            <LangButtons class="mb-25" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="mb-32"
                v-model="editedAddition.name[locale]"
                :label="$t('otherSettings.additionName')"
                :placeholder="$t('otherSettings.additionName')"
                :errors="errors?.name"
                @onChange="$filters.clearError(errors, 'name')"
            />
            <CRInput
                class="mb-32"
                v-model="editedAddition.price"
                type="number"
                :label="$t('otherSettings.priceForADay')"
                :placeholder="$t('otherSettings.priceForADay')"
                :errors="errors?.price"
                @onChange="$filters.clearError(errors, 'price')"
            />

            <div class="img-preview" :style="backgroundStyles(editedAddition.picture?.picturePath.thumbnail)" />

            <CRButton class="my-10" upload pattern="thin" @handleUpload="handleUpload">
                {{ $t('otherSettings.addThumbnail') }}
            </CRButton>
        </div>

        <CRButton @click="isCreateModalOpen = true">
            {{ $t('buttons.add') }}
        </CRButton>

        <CreateAdditionModal
            v-if="isCreateModalOpen"
            @close="isCreateModalOpen = false"
            @created="additions.push($event)"
        />
    </div>
</template>

<script>
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { langMixin, toasterMixin } from '@/utils/mixins';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox';
    import { AdditionApi, PictureApi } from '@/api';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { cloneDeep, throttle } from 'lodash';
    import CreateAdditionModal from '@/components/Modals/CreateAdditionModal';
    import { handleUploadImage } from '@/helpers/UploadImageHelper';

    export default {
        name: 'AdditionsSettings',
        components: { CreateAdditionModal, LangButtons, CRCheckbox, CRButton, CRInput },
        mixins: [langMixin, toasterMixin],
        data() {
            return {
                backgroundStyles,
                additions: [],
                editedAddition: null,
                errors: {},
                isCreateModalOpen: false,
                images: [],
            };
        },
        methods: {
            additionClickHandler: throttle(async function (addition) {
                try {
                    const response = await AdditionApi.update(addition.id, { isHidden: !addition.isHidden });
                    addition.isHidden = response.data.isHidden;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1500),

            async deleteAddition(deletedAddition) {
                try {
                    const additionName = this.additions.find((addition) => deletedAddition.id === addition.id).name;
                    await AdditionApi.destroy(deletedAddition.id);

                    this.notify(additionName, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);

                    this.additions = this.additions.filter((addition) => deletedAddition.id !== addition.id);
                    this.editedAddition = null;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async uploadAdditionImage(image, additionId) {
                let formData = new FormData();

                formData.append('pictures[]', image);
                formData.append('picturable_type', 'addition');
                formData.append('picturable_id', additionId);

                if (this.editedAddition.picture) {
                    await PictureApi.destroy(this.editedAddition.picture.id);
                }

                const response = await PictureApi.store(formData);
                const newPicture = response.data[0];

                this.additions.find((addition) => addition.id === additionId).picture = newPicture;
                this.editedAddition.picture = newPicture;

                return response.data;
            },

            async handleUpload($event) {
                try {
                    handleUploadImage($event.target.files, this.images);

                    if (this.editedAddition) {
                        this.images = await this.uploadAdditionImage($event.target.files[0], this.editedAddition.id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async fetchAdditions() {
                try {
                    const response = await AdditionApi.getAll();
                    this.additions = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async editAdditionHandler(addition) {
                if (this.editedAddition?.id === addition.id) {
                    try {
                        const response = await AdditionApi.update(this.editedAddition.id, {
                            price: this.editedAddition.price,
                            name: this.editedAddition.name,
                        });

                        let addition = this.additions.find((addition) => addition.id === response.data.id);
                        if (addition) {
                            addition.price = response.data.price;
                            addition.name = response.data.name;
                        }

                        this.notify(this.editedAddition.name, this.CRUDTypeEnum.UPDATED, this.TYPE.SUCCESS);

                        this.editedAddition = null;
                    } catch (error) {
                        this.errors = error.errors;
                    }
                } else {
                    this.editedAddition = cloneDeep(addition);
                }
            },
        },
        async mounted() {
            await this.fetchAdditions();
        },
    };
</script>

<style scoped lang="scss">
    .additions__wrapper {
        .additions-container {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 20px;

            .radio {
                width: 310px;
                margin: 0 5px 5px 0;
            }

            .addition__inner {
                @include row-align-center-justify-between;
                width: 100%;
                position: relative;

                .content {
                    color: $primary;

                    p {
                        font-size: $font-14;
                        margin-bottom: 10px;
                    }
                }
                .operational-buttons {
                    @include row-align-center-justify-end;

                    right: 0;
                    top: 0;

                    position: absolute;
                    z-index: 2;

                    .button {
                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }

                .img {
                    width: 72px;
                    height: 72px;

                    background-image: url('../../../assets/images/backgrounds/default-addition-bg.png');

                    box-shadow: $box-shadow-dark;
                    border-radius: $br-10;
                }
            }
        }

        .edit-container {
            margin-top: 30px;

            .img-preview {
                width: 300px;
                height: 300px;

                background-image: url('../../../assets/images/backgrounds/default-addition-bg.png');

                box-shadow: $box-shadow-dark;
                border-radius: $br-10;
            }
        }
    }
</style>
