<template>
    <div class="promotion__wrapper" v-if="promotionText">
        <h2 class="mb-30">{{ $t('otherSettings.promotionalCardInformation') }}</h2>
        <LangButtons class="mb-30" :locale="locale" @selectLocale="selectLocale" />
        <CRTextarea
            class="mb-30"
            v-model="promotionText.text[locale]"
            :errors="errors?.text"
            :label="$t('otherSettings.promotionText')"
            :placeholder="$t('placeholder.enterPromotionText')"
        />
        <CRButton @click="updatePromotionText">
            {{ $t('buttons.update') }}
        </CRButton>
    </div>
</template>

<script>
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { PromotionTextApi } from '@/api';
    import { langMixin } from '@/utils/mixins';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'PromotionSettings',
        components: { CRTextarea, CRButton, LangButtons },
        mixins: [langMixin],
        data() {
            return {
                promotionText: null,

                errors: {},
            };
        },
        methods: {
            async getPromotionText() {
                try {
                    const response = await PromotionTextApi.get();
                    this.promotionText = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updatePromotionText() {
                try {
                    const response = await PromotionTextApi.update(
                        { text: this.promotionText.text },
                        this.promotionText.id
                    );

                    this.promotionText = response.data;

                    this.$filters.toast(
                        `${this.$t('otherSettings.promotionText')}${this.$t('otherSettings.updated')}`,
                        {
                            type: TYPE.SUCCESS,
                        }
                    );
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },

        async mounted() {
            await this.getPromotionText();
        },
    };
</script>
